<template>
  <div class="product-marketing">
    <section class="py-4 bg11 mobile-top">
      <div class="container">
        <div class="row">
          <div
            class="
              col-12 col-sm-12 col-md-10
              offset-md-1
              col-xs-8 col-sm-8 col-8 col-lg-8
              offset-lg-2
            "
          >
            <h1 v-if="loading === true" class="text-white">
              Loading Announcement...
            </h1>
            <h1 v-else class="text-white" v-html="posts[0].title.rendered"></h1>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div>
        <div class="row">
          <div
            v-if="loading == true && !posts.length"
            class="col-12 col-xs-12 col-sm-12 col-12 col-lg-12"
          >
            <div class="flex-box-wrap" style="margin-top: 250px">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />
                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !posts.length"
            class="col-12 col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
          >
            <div>
              <img src="@/assets/img/sad.svg" />
              <p>Sorry, nothing was found...</p>
            </div>
          </div>
          <div
            v-else
            class="
              col-12 col-sm-12 col-md-10
              offset-md-1
              col-xs-8 col-sm-8 col-8 col-lg-8
              offset-lg-2
              mb20
              mt20
            "
          >
            <router-link
              :to="{ path: $store.state.route.from.fullPath }"
              class="list-btn mb10"
              >Back</router-link
            >
          </div>
        </div>

        <div class="row row-eq-height">
          <div
            v-bind:key="post.id"
            v-for="post in posts"
            class="
              col-12 col-sm-12 col-md-10
              offset-md-1
              col-xs-8 col-sm-8 col-8 col-lg-8
              offset-lg-2
              mb20
            "
          >
            <div class="post" id="post">
              <!--
							<h6 class="post-title" v-html="post.title.rendered"></h6>
              -->
              <!-- card -->
              <div v-html="posts[0].content.rendered" id="post-content"></div>
              <!--
              <button>Read More</button>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end other column -->

    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";

//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts
//http://mktginfo.spacesaver.com/wp-json/wp/v2/posts?slug=post-slug

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6",
  },
};

export default {
  name: "AnnouncementSingle",
  components: {
    AtomSpinner,
    Avatar,
  },
  data() {
    return {
      //announcement: process.env.VUE_APP_TRAILBLAZOR,
      search: "",
      posts: [],
      pageSlug: "",
      loading: true,
      countOfPage: 25,
      currPage: 1,
    };
  },
  methods: {
    firstLoad: function () {
      this.pageSlug = this.$route.params.slug;
      this.loading = true;
      this.$store.commit("setActivePage", "initiatives");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return (
        axios
          //http://spacenetcontent.spacesaver.com/wp-json/wp/v2/news
          .get(
            `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/news?slug=${this.pageSlug}`
          )
          .then((response) => {
            //console.log(response);
            this.posts = response.data;
            this.loading = false;
            document
              .getElementById("mobileScrollTarget")
              .scrollIntoView({ behavior: "smooth" });
          })
      );
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function () {
      return Math.ceil(this.posts.length / this.countOfPage);
    },
  },
  created() {
    this.$store.commit("showNavigation");
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad();
    //console.log(this.$route.params.slug);
    // console.log(this.$route.path);
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post {
  background: #fff;
  padding: 20px;
  margin-bottom: 50px;
  height: 100%;
  display: block;
  position: relative;
}
.post-excerpt {
  font-size: 14px;
}
.post button {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #00b4b4;
  color: #fff;
  border-radius: 4px;
}

.flex-box-wrap {
  margin-top: 200px;
}
.post-title {
  font-size: 2.5em;
}
#post-content img {
  width: 100% !important;
  max-width: 100% !important;
  height: auto !important;
}
</style>
